<template>
  <div>
    <Transition name="slide-fade">
      <div
        v-show="isExpanded"
        class="card card-custom gutter-b mr-8 shadow"
        style="z-index: 4; position: fixed"
        :style="{ top: getCompactHeaderTop() }"
      >
        <div class="card-body pb-0">
          <div class="d-flex">
            <div class="flex-grow-1">
              <div class="d-flex align-items-center justify-content-between">
                <div class="d-flex flex-wrap align-items-center mr-3" style="row-gap: 0.5rem">
                  <template v-if="!isInitialLoading && reiseterminObj">
                    <a
                      class="d-flex align-items-center flex-wrap text-dark text-nowrap font-size-h5 font-weight-bold mr-3"
                      >{{ reiseterminObj.reiseterminkuerzel }}</a
                    >
                    <!-- <StateWidget
                      :mode="0"
                      :state="reiseterminObj.reiseterminState"
                      :customState="reiseterminObj.customState"
                      :bearbeiter="$store.getters.getUsernameById(reiseterminObj.bearbeiter)"
                      objType="reisetermine"
                      class="mr-2"
                    ></StateWidget> -->
                    <PaxWidget
                      class="mr-2"
                      :paxObject="reiseterminObj.pax"
                      :kalkPax="reiseterminObj.metadata.kalkPax"
                    ></PaxWidget>
                    <ReleaseprozessIcons class="mr-4" :reiseterminObject="reiseterminObj || {}" />
                  </template>
                  <b-skeleton v-if="isInitialLoading" height="24px" width="600px"></b-skeleton>
                  <div v-if="!isInitialLoading && reiseterminObj" class="d-flex flex-wrap my-2"></div>

                  <template v-if="reiseterminObj">
                    <div
                      v-for="(element, index) in headerArray"
                      :key="index"
                      class="d-flex justify-content-between align-items-center mr-4"
                    >
                      <i v-if="element.icon" class="mr-1" :class="element.icon" />
                      <template v-if="element.href">
                        <span v-if="element.data && !element.icon" class="text-muted mr-2"
                          >{{ element.label }}:</span
                        >
                        <a :href="element.href" target="_blank" class="text-primary">{{
                          element.data || element.label
                        }}</a>
                      </template>
                      <template v-else-if="element.to">
                        <span v-if="element.data && !element.icon" class="text-muted mr-2"
                          >{{ element.label }}:</span
                        >
                        <router-link :to="element.to" target="_blank" class="text-primary">{{
                          element.data || element.label
                        }}</router-link>
                      </template>
                      <template v-else>
                        <span v-if="!element.icon" class="text-muted mx-2">{{ element.label }}:</span>
                        <span class="text-dark-75 font-weight-bolder mr-2">{{ element.data }}</span>
                      </template>
                    </div>
                    <b-button
                      v-allowedRoles="[Role.ADMIN, Role.PM, Role.DOM]"
                      class="btn btn-light-primary btn-icon btn-xl ml-2 text-primary text-hover-white"
                      variant="primary"
                      aria-label="bearbeiten"
                      title="Reise bearbeiten"
                      :to="{
                        name: 'reisen-edit-view',
                        params: { reisekuerzel: reiseterminObj.reise.reisekuerzel },
                      }"
                    >
                      <i class="flaticon-edit icon-1x" />
                    </b-button>
                  </template>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div @click="onExpandClick" class="py-2 d-block text-center cursor-pointer hover-primary">
          <i class="ki ki-double-arrow-up"></i>
        </div>
      </div>
    </Transition>
    <Transition name="slide-fade">
      <div
        v-show="!isExpanded"
        class="card card-custom gutter-b mr-8 shadow-sm"
        style="z-index: 2; position: fixed"
        :style="{ top: getCompactHeaderTop() }"
      >
        <div @click="onExpandClick" class="pt-4 pb-2 px-8 d-block text-center cursor-pointer hover-primary">
          <i class="ki" :class="[isExpanded ? 'ki-double-arrow-up' : 'ki-double-arrow-down']"></i>
        </div>
      </div>
    </Transition>
  </div>
</template>
<style lang="scss" scoped>
.slide-fade-enter-active {
  transform: translateY(-100px);
  opacity: 0;
  transition: all 0.3s ease-out;
}

.slide-fade-enter-active,
.slide-fade-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-to {
  transform: translateY(0px);
  opacity: 1;
}
.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateY(-100px);
  opacity: 0;
}
</style>
<script>
import PaxWidget from '@/components/produkte/reisetermine/pax-widget.vue';
import StateWidget from '@/components/common/state-widget.vue';
import { calculateReleaseStartDateDiff } from '@/core/common/helpers/utils';
import ReleaseprozessIcons from '@/components/produkte/reisetermine/releaseprozess-icons.vue';

const moment = require('moment');

export default {
  components: {
    PaxWidget,
    StateWidget,
    ReleaseprozessIcons,
  },
  props: {
    reiseterminObj: Object,
    isInitialLoading: Boolean,
  },
  data() {
    return {
      isExpanded: true,
    };
  },
  computed: {
    headerArray() {
      return [
        {
          label: this.$t('REISEN.COMMON.REGION'),
          data: this.$t('REISEN.REGIONS.' + this.reiseterminObj.reise.region),
          icon: 'flaticon-earth-globe',
        },
        {
          label: this.$t('REISEN.COMMON.COUNTRY'),
          data: this.$t('REISEN.COUNTRIES.' + this.reiseterminObj.reise.country),
          icon: 'flaticon-earth-globe',
        },
        {
          label: this.$t('COMMON.REISEWIKI'),
          href: 'https://skr.de/+' + this.reiseterminObj.reise.reisekuerzel,
          icon: 'flaticon2-placeholder',
        },
        {
          label: this.$t('REISETERMINE.GROUPS.secondLevel.Reisekuerzel'),
          data: this.reiseterminObj.reise.reisekuerzel,
          to: {
            name: 'reisen-view',
            params: { reisekuerzel: this.reiseterminObj.reise.reisekuerzel },
          },
          icon: 'flaticon2-placeholder',
        },
        {
          label: this.$t('COMMON.KONTAKTDATENLISTE'),
          href:
            new Date(this.reiseterminObj.startdatum).getFullYear() === 2022
              ? 'https://docs.google.com/spreadsheets/d/1jLZQswDFvOvXLT9qlg44QPP5tDp0mcvt5z0PYof_GG8/edit?usp=sharing'
              : new Date(this.reiseterminObj.startdatum).getFullYear() === 2023
              ? 'https://docs.google.com/spreadsheets/d/1ISyLNU-nPPi_M7Yrtm_r55Q789Y_9xdpUlRXZpbW-5k/edit#gid=0'
              : null,
          icon: 'flaticon2-user-outline-symbol',
        },
        {
          label: this.$t('REISETERMINE.VERSANDREISEUNTERLAGEN'),
          data: this.reiseterminObj?.unterlagenversandDatum
            ? this.$options.filters.date(this.reiseterminObj.unterlagenversandDatum)
            : '-',
        },
        {
          label: this.$t('REISEN.COMMON.RELEASEDATE'),
          data: `${this.$options.filters.date(
            this.reiseterminObj.releasedatum
          )}${calculateReleaseStartDateDiff(
            this.reiseterminObj.releasedatum,
            this.reiseterminObj.startdatum
          )}`,
        },
        {
          label: this.$t('REISEN.COMMON.DEPARTURE'),
          data: this.reiseterminObj?.abreisedatum
            ? this.$options.filters.date(this.reiseterminObj.abreisedatum)
            : '-',
        },
        {
          label: this.$t('REISEN.COMMON.START'),
          data: `${this.$options.filters.date(this.reiseterminObj.startdatum)}`,
        },
        {
          label: this.$t('REISEN.COMMON.TRAVELPERIOD'),
          data: this.reiseterminObj?.abreisedatum
            ? `${this.$options.filters.date(
                this.reiseterminObj.abreisedatum
              )} -  ${this.$options.filters.date(
                moment(this.reiseterminObj.abreisedatum).add(
                  this.reiseterminObj?.metadata?.reisedauer
                    ? this.reiseterminObj?.metadata?.reisedauer - 1
                    : 0,
                  'days'
                )
              )}`
            : '-',
        },
        {
          label: this.$t('COLUMNNAMES.ReiseterminBlankData_Garantiert'),
          data: this.reiseterminObj.garantiert ? 'Ja' : 'Nein',
        },
        {
          label: this.$t('COLUMNNAMES.ReiseterminBlankData_StopSale'),
          data: this.reiseterminObj.stopSale ? 'Ja' : 'Nein',
        },
        { label: this.$t('COLUMNNAMES.Reisedauer'), data: this.reiseterminObj?.metadata?.reisedauer || '-' },
        {
          label: this.$t('COLUMNNAMES.Umsatz'),
          data: this.$options.filters.currency(this.reiseterminObj.umsatz),
        },
        {
          label: this.$t('REISEN.COMMON.BASISPREIS'),
          data: this.$options.filters.currency(this.reiseterminObj.basisPreis) || '-',
        },
        { label: this.$t('COMMON.KOMMENTARE'), data: this.$store.getters.getCommentsCount },
      ];
    },
  },
  methods: {
    getCompactHeaderTop() {
      const subheaderElement = document.getElementById('kt_subheader');
      let mobileHeaderElementBottom = null;
      // If subheader is not found we take 0 as bottom
      const suberheaderElementBottom = subheaderElement ? subheaderElement.getBoundingClientRect().bottom : 0;
      // On mobile it can be below zero so we get the mobile header
      if (suberheaderElementBottom < 0) {
        const mobileHeaderElement = document.getElementById('kt_header_mobile');
        mobileHeaderElementBottom = mobileHeaderElement
          ? mobileHeaderElement.getBoundingClientRect().bottom
          : 0;
      }

      const compactHeaderTop = (mobileHeaderElementBottom || suberheaderElementBottom) - 10;
      return compactHeaderTop + 'px';
    },
    onExpandClick() {
      this.isExpanded = !this.isExpanded;
    },
  },
};
</script>
