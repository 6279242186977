<template>
  <b-table
    style="border: 1px solid rgb(0, 0, 0, 0.1); border-radius: 5px; margin-top: 12px; white-space: nowrap"
    :items="reiseterminData"
    :fields="reiseterminFields"
    responsive
    hover
    @row-clicked="rowClicked"
    head-variant="light"
  >
    <template v-slot:cell(paxWidget)="row">
      <PaxWidget :paxObject="row.item.pax" :kalkPax="row.item.metadata.kalkPax"></PaxWidget>
    </template>
    <template v-slot:cell(garantiert)="row">
      <i v-if="row.item.garantiert" class="fas fa-check text-success"></i>
      <i v-else class="fas fa-times text-danger"></i>
    </template>
    <template v-slot:cell(reise.prioritaet)="data">
      <b-badge>{{ getValueByPriority(data.value) }}</b-badge>
    </template>
    <template v-slot:cell(zimmerkontingenteWidget)="row">
      <ZimmerkontingenteWidget
        :zimmerkontingente="row.item?.zimmerkontingente"
        :reiseterminId="row.item?.id"
        :isZvc="true"
      ></ZimmerkontingenteWidget>
    </template>
    <template v-slot:cell(nochErwartetePax)="row">
      <span style="font-size: 1rem" :class="`badge badge-${nochErwartetePaxVariant(row.item)}`">{{
        row.item.nochErwartetePax
      }}</span>
    </template>
    <template v-slot:cell(paxBisMax)="row">
      <span style="font-size: 1rem" :class="`badge badge-${paxBisMaxVariant(row.item)}`">{{
        row.item.paxBisMax
      }}</span>
    </template>
  </b-table>
</template>
<script>
import { differenceInDays, format } from 'date-fns';
import { formatDateWithoutHours } from '@/core/common/helpers/utils';
import PaxWidget from '@/components/produkte/reisetermine/pax-widget.vue';
import configPriority from '@/core/produkte/reisen/priority.config.json';
import ZimmerkontingenteWidget from '@/components/common/zimmerkontingente/zimmerkontingente-widget.vue';

export default {
  props: {
    reiseterminData: { type: Array, required: true },
  },
  components: { PaxWidget, ZimmerkontingenteWidget },
  data() {
    return {
      reiseterminFields: [
        {
          tdClass: 'align-middle',
          key: 'reiseterminkuerzel',
          label: 'Reisekürzel',
          formatter: value => value?.substring(0, 6),
        },
        {
          tdClass: 'align-middle',
          key: 'reise.prioritaet',
          label: 'Priorität',
        },
        {
          tdClass: 'align-middle',
          key: 'abreisedatum',
          label: 'Abreisedatum',
          formatter: value => {
            return this.formatDateWithoutHours(value);
          },
        },
        {
          tdClass: 'align-middle',
          key: 'garantiert',
          label: 'Garantiert',
        },
        {
          tdClass: 'align-middle',
          key: 'garantiert',
          label: 'Garantiert',
        },
        {
          tdClass: 'align-middle',
          key: 'releasedatum',
          label: 'Tage bis Release',
          formatter: value => {
            if (value) {
              const releasedate = new Date(value);
              const tageBisRelease = differenceInDays(releasedate, new Date());
              const formattedReleasedate = format(releasedate, 'dd.MM.yyyy');
              return `${tageBisRelease} (${formattedReleasedate})`;
            }
            return '';
          },
        },
        {
          tdClass: 'align-middle',
          key: 'zimmerkontingenteWidget',
          label: 'Zimmerkontingente',
        },
        {
          tdClass: 'align-middle',
          key: 'paxWidget',
          label: 'PAX',
        },
        {
          tdClass: 'align-middle',
          key: 'metadata.erwartetePax',
          label: 'Erw. PAX',
          formatter: value => value ?? '-',
        },
        {
          tdClass: 'align-middle',
          key: 'nochErwartetePax',
          label: 'Noch erw. PAX',
        },
        {
          tdClass: 'align-middle',
          key: 'paxBisMax',
          label: 'PAX bis Max',
        },
      ],
    };
  },
  computed: {},
  methods: {
    rowClicked(item) {
      window.open('/#/reisetermine/' + item.id, '_blank');
    },
    formatDateWithoutHours,
    getValueByPriority(priority) {
      return configPriority[priority].value;
    },
    paxBisMaxVariant(item) {
      const { paxBisMax, zimmerkontingentePersonenFrei } = item;
      return paxBisMax > zimmerkontingentePersonenFrei ? 'warning' : 'secondary';
    },
    nochErwartetePaxVariant(item) {
      const { zimmerkontingentePersonenFrei, nochErwartetePax } = item;
      return nochErwartetePax <= zimmerkontingentePersonenFrei ? 'success' : 'danger';
    },
  },
};
</script>
<style scoped></style>
