<template>
  <div>
    <b-table
      style="border: 1px solid rgb(0, 0, 0, 0.1); border-radius: 5px; margin-top: 12px"
      :items="vorgangData"
      :fields="vorgangFields"
      :busy="isVorgangLoading"
      responsive
      hover
      head-variant="light"
      @row-clicked="rowClicked"
    >
      <template v-slot:cell(flugticketAusgestellt)="row">
        <i
          :class="row.item.flugticketAusgestellt ? 'fas fa-check text-success' : 'fas fa-times text-danger'"
        ></i>
      </template>
      <template v-slot:cell(thWertMain)="row">
        <template v-if="!getThwerteAtBuchungsdatumByVorgangIdisLoading(row.item.id)">
          {{ getThwerteAtBuchungsdatumByVorgangId(row.item.id, 'thWertMain') }}
        </template>
        <b-spinner v-else small></b-spinner>
      </template>
      <template v-slot:cell(thWertInlandsflug)="row">
        <template v-if="!getThwerteAtBuchungsdatumByVorgangIdisLoading(row.item.id)">
          {{ getThwerteAtBuchungsdatumByVorgangId(row.item.id, 'thWertInlandsflug') }}
        </template>
        <b-spinner v-else small></b-spinner>
      </template>
      <template v-slot:cell(thWertGesamt)="row">
        <template v-if="!getThwerteAtBuchungsdatumByVorgangIdisLoading(row.item.id)">
          {{ getThwerteAtBuchungsdatumByVorgangId(row.item.id, 'thWertGesamt') }}
        </template>
        <b-spinner v-else small></b-spinner>
      </template>
    </b-table>
  </div>
</template>
<script>
import { format } from 'date-fns';
import VorgangStateWidget from '@/components/vorgaenge/vorgang-state-widget.vue';
import { GET_TH_WERTE_AT_BUCHUNGSDATUM_BY_VORGANG_ID } from '@/core/vorgaenge/stores/vorgaenge.module';
import { mapState } from 'vuex';
export default {
  props: { vorgangData: { type: Array, required: true } },
  components: { VorgangStateWidget },
  data() {
    return {
      isVorgangLoading: false,
      vorgangDataExpanded: [],
      vorgangFields: [
        {
          tdClass: 'align-middle',
          key: 'id',
          label: 'BNR',
        },
        {
          tdClass: 'align-middle',
          key: 'pnr',
          label: 'PNR',
          formatter: value => value.join(', '),
        },
        {
          tdClass: 'align-middle',
          key: 'teilnehmerNames',
          label: 'Teilnehmernamen',
        },
        {
          tdClass: 'align-middle',
          key: 'flugticketAusgestellt',
          label: 'Flugticket ausgestellt',
        },
        {
          tdClass: 'align-middle',
          key: 'thWertMain',
          label: 'TH Langstrecke',
        },
        {
          tdClass: 'align-middle',
          key: 'thWertInlandsflug',
          label: 'TH Inlandsflug',
        },
        {
          tdClass: 'align-middle',
          key: 'thWertGesamt',
          label: 'TH Gesamt',
        },
        {
          tdClass: 'align-middle',
          key: 'ticketingTimelimit',
          label: 'TKTL',
        },
      ],
    };
  },
  computed: {
    ...mapState({
      thwerteAtBuchungsdatumByVorgangId: state => state.vorgaenge.thwerteAtBuchungsdatumByVorgangId,
    }),
  },
  mounted() {
    const reiseterminIds = [
      ...new Set(this.vorgangData.map(vorgang => vorgang.hotelleistungen[0].reisetermin.id)),
    ];
    if (reiseterminIds.length === 1) {
      this.$store.dispatch(GET_TH_WERTE_AT_BUCHUNGSDATUM_BY_VORGANG_ID, {
        reiseterminId: reiseterminIds[0],
        vorgaenge: this.vorgangData,
      });
    }
  },
  methods: {
    getThwerteAtBuchungsdatumByVorgangIdisLoading(vorgangId) {
      return this.thwerteAtBuchungsdatumByVorgangId?.[vorgangId]?.isLoading;
    },
    getThwerteAtBuchungsdatumByVorgangId(vorgangId, key) {
      const vorgangData = this.thwerteAtBuchungsdatumByVorgangId?.[vorgangId]?.data;
      if (vorgangData && vorgangData[key]) {
        return this.$options.filters.currency(vorgangData[key]);
      }
      return '-';
    },
    kundenName(item) {
      if (item?.kunde?.master) {
        return item?.kunde?.master.vorname + ' ' + item?.kunde?.master.nachname;
      } else return '';
    },
    formatDateWithoutHours(date) {
      if (date) {
        return format(new Date(date), 'dd.MM.yyyy');
      } else return '';
    },
    rowClicked(item) {
      window.open('/#/reisetermine/' + item.hotelleistungen[0].reisetermin.id, '_blank');
    },
  },
};
</script>
