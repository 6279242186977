<template>
  <div>
    <Editor
      v-model="commentText"
      :activeEditor="active"
      :disableTable="true"
      :placeholder="'Schreibe einen Kommentar...'"
    />
    <b-button
      v-if="commentMode === ''"
      size="sm"
      class="mt-2"
      :variant="commentText.length > 0 ? 'primary' : ''"
      :disabled="commentText.length === 0"
      @click="emitSaveEvent"
      >Speichern</b-button
    >
    <div v-if="commentMode === 'edit'">
      <b-button
        size="sm"
        class="mt-2 mr-1"
        :variant="commentText.length > 0 ? 'primary' : ''"
        :disabled="commentText.length === 0"
        @click="emitSaveEditEvent"
        >Ändern</b-button
      >
      <b-button size="sm" class="mt-2" variant="danger" @click="emitCancelEditEvent">Abbrechen</b-button>
    </div>
    <div v-if="commentMode === 'reply'">
      <b-button
        size="sm"
        class="mt-2 mr-1"
        :variant="commentText.length > 0 ? 'primary' : ''"
        :disabled="commentText.length === 0"
        @click="emitSaveReplyEvent"
        >Antworten</b-button
      >
      <b-button size="sm" class="mt-2" variant="danger" @click="emitCancelReplyEvent">Abbrechen</b-button>
    </div>
  </div>
</template>
<script>
import Editor from '@/components/aufgaben/Editor.vue';
import { SET_COMMENT_TEXT } from '@/core/aufgaben/stores/aufgaben.module';
import { mapState } from 'vuex';

export default {
  components: { Editor },
  props: { isActive: { type: Boolean } },
  data() {
    return { active: false };
  },
  computed: {
    ...mapState({
      commentMode: state => state.aufgaben.comments.mode,
      users: state => state.users.users,
    }),
    commentText: {
      get() {
        return this.$store.state.aufgaben.comments.commentText;
      },
      set(value) {
        this.$store.commit(SET_COMMENT_TEXT, value);
      },
    },
  },
  methods: {
    emitSaveEvent() {
      this.$emit('save-comment', this.commentText);
    },
    emitCancelEditEvent() {
      this.$emit('cancel-edit');
    },
    emitSaveEditEvent() {
      this.$emit('save-edit-comment', this.commentText);
    },
    emitSaveReplyEvent() {
      this.$emit('save-reply-comment', this.commentText);
    },
    emitCancelReplyEvent() {
      this.$emit('cancel-reply');
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.active = true;
    });
  },
  destroyed() {
    this.active = true;
  },
};
</script>
<style scoped></style>
