<template>
  <div class="card card-custom gutter-b">
    <div class="card-body">
      <div class="d-flex">
        <div class="flex-grow-1">
          <div class="d-flex align-items-center justify-content-between">
            <div class="d-flex align-items-center mr-3">
              <template v-if="!isInitialLoading && reiseterminObj">
                <a
                  class="d-flex align-items-center flex-wrap text-dark text-nowrap font-size-h5 font-weight-bold mr-3"
                >
                  {{ reiseterminObj.reiseterminkuerzel }}
                </a>
                <b-badge class="mr-4">{{ getStateGerman(reiseterminObj?.state) }}</b-badge>
                <ReleaseprozessIcons class="mr-4" :reiseterminObject="reiseterminObj" />
              </template>
              <b-skeleton v-if="isInitialLoading" height="24px" width="600px"></b-skeleton>
              <div v-if="!isInitialLoading && reiseterminObj" class="d-flex flex-wrap my-2">
                <a class="text-muted font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2">
                  <i class="flaticon-earth-globe mr-1"></i>
                  {{ $t('REISEN.REGIONS.' + reiseterminObj.reise.region) }}
                </a>
                <a class="text-muted font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2">
                  <i class="flaticon-earth-globe mr-1"></i>
                  {{ $t('REISEN.COUNTRIES.' + reiseterminObj.reise.country) }}
                </a>

                <a
                  :href="'https://skr.de/+' + reiseterminObj.reise.reisekuerzel"
                  target="_blank"
                  class="text-muted text-hover-primary font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2"
                >
                  <i class="flaticon2-placeholder mr-1"></i>
                  {{ $t('COMMON.REISEWIKI') }}
                </a>
                <a
                  v-if="kontaktdatenlisteLink"
                  :href="kontaktdatenlisteLink"
                  target="_blank"
                  class="text-muted text-hover-primary font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2"
                >
                  <i class="flaticon2-user-outline-symbol mr-1"></i>
                  {{ $t('COMMON.KONTAKTDATENLISTE') }}
                </a>
              </div>
            </div>
            <div v-if="reiseterminObj" class="my-lg-0 my-1">
              <b-button
                v-allowedRoles="[Role.ADMIN, Role.PM, Role.DOM]"
                class="btn btn-light-primary btn-icon btn-xl ml-2 mb-2 text-primary text-hover-white"
                variant="primary"
                aria-label="bearbeiten"
                title="Reise bearbeiten"
                :to="{
                  name: 'reisen-edit-view',
                  params: { reisekuerzel: reiseterminObj.reise.reisekuerzel },
                }"
              >
                <i class="flaticon-edit icon-1x" />
              </b-button>
            </div>
          </div>
          <div class="py-2 d-flex"></div>
          <div class="d-flex align-items-baseline flex-wrap justify-content-between">
            <div class="d-flex flex-wrap align-items-baseline py-2">
              <div
                v-if="!isInitialLoading && reiseterminObj"
                class="d-flex flex-wrap align-items-baseline mr-10"
                style="row-gap: 1rem"
              >
                <div class="mr-6 d-flex flex-column align-baseline">
                  <div class="font-weight-bold mb-4" v-t="'REISEN.COMMON.RELEASE'"></div>
                  <span
                    title="Releasedatum (Anzahl Tage zwischen Start und Release)"
                    class="rounded p-2 d-flex text-nowrap bg-light-primary text-uppercase text-primary"
                  >
                    {{ reiseterminObj.releasedatum | date }} {{ releaseStartDateDiff }}
                  </span>
                </div>
                <div class="mr-6 d-flex flex-column align-items-baseline">
                  <div class="font-weight-bold mb-4">{{ $t('REISETERMINE.VERSANDREISEUNTERLAGEN') }}</div>
                  <span class="rounded p-2 d-flex text-nowrap bg-light-primary text-uppercase text-primary">
                    {{ versandReiseUnterlagen }}
                  </span>
                </div>
                <div class="mr-6 d-flex flex-column align-items-baseline">
                  <div class="font-weight-bold mb-4" v-t="'REISEN.COMMON.DEPARTURE'"></div>
                  <span class="rounded p-2 d-flex text-nowrap bg-light-primary text-uppercase text-primary">
                    {{ abreisedatum }}
                  </span>
                </div>
                <div class="mr-6 d-flex flex-column align-items-baseline">
                  <div class="font-weight-bold mb-4" v-t="'REISEN.COMMON.START'"></div>
                  <span class="rounded p-2 d-flex text-nowrap bg-light-primary text-uppercase text-primary">
                    {{ reiseterminObj.startdatum | date }}
                  </span>
                </div>
                <div class="mr-6 d-flex flex-column align-items-baseline">
                  <div class="font-weight-bold mb-4" v-t="'REISEN.COMMON.TRAVELPERIOD'"></div>
                  <span class="rounded p-2 d-flex text-nowrap bg-light-primary text-uppercase text-primary">
                    {{ reisezeitraum }}
                  </span>
                </div>
                <div class="mr-6 d-flex flex-column align-items-baseline">
                  <div class="font-weight-bold mb-4" v-t="'REISEN.COMMON.PAX'"></div>
                  <PaxWidget
                    :paxObject="reiseterminObj.pax"
                    :kalkPax="reiseterminObj.metadata.kalkPax"
                  ></PaxWidget>
                </div>
                <div class="mr-6 d-flex flex-column align-items-baseline">
                  <div class="font-weight-bold mb-4">
                    {{ $t('COLUMNNAMES.ReiseterminBlankData_Garantiert') }}
                  </div>
                  <span class="rounded d-flex text-nowrap p-2 bg-light-primary text-uppercase text-primary">
                    <i
                      :class="
                        reiseterminObj.garantiert ? 'fas fa-check text-success' : 'fas fa-times text-danger'
                      "
                    />
                  </span>
                </div>
                <div class="mr-6 d-flex flex-column align-items-baseline">
                  <div class="font-weight-bold mb-4">
                    {{ $t('COLUMNNAMES.ReiseterminBlankData_StopSale') }}
                  </div>
                  <span class="rounded d-flex text-nowrap p-2 bg-light-primary text-uppercase text-primary">
                    <i
                      v-if="reiseterminObj.stopSale"
                      class="flaticon2-exclamation mr-1 text-white rounded bg-danger"
                    />
                    <span v-else>-</span>
                  </span>
                </div>
                <div class="mr-6 d-flex flex-column align-items-baseline">
                  <div class="font-weight-bold mb-4">{{ $t('REISEN.COMMON.SALES') }}</div>
                  <span class="rounded d-flex text-nowrap p-2 bg-light-primary text-uppercase text-primary">
                    {{ reiseterminObj.umsatz | currency }}
                  </span>
                </div>
                <div class="mr-6 d-flex flex-column align-items-baseline">
                  <div class="font-weight-bold mb-4">{{ $t('REISEN.COMMON.BASISPREIS') }}</div>
                  <span class="rounded d-flex text-nowrap p-2 bg-light-primary text-uppercase text-primary">
                    {{ $options.filters.currency(reiseterminObj.basisPreis) || '-' }}
                  </span>
                </div>
                <div class="mr-6 d-flex flex-column align-items-baseline">
                  <div class="font-weight-bold mb-4">{{ $t('COMMON.KOMMENTARE') }}</div>
                  <span class="rounded d-flex text-nowrap p-2 bg-light-primary text-uppercase text-primary">
                    {{ $store.getters.getCommentsCount }}
                  </span>
                </div>
                <div class="mr-6 d-flex flex-column align-items-baseline">
                  <div class="font-weight-bold mb-4">{{ $t('COLUMNNAMES.Reisedauer') }}</div>
                  <span class="rounded d-flex text-nowrap p-2 bg-light-primary text-uppercase text-primary">
                    {{ reiseterminObj.metadata.reisedauer || '-' }}
                  </span>
                </div>
                <ReiseterminViewDetailHeaderMetadata :metadata="reiseterminObj.metadata" />
                <div class="mr-6 d-flex flex-column align-items-baseline">
                  <div class="font-weight-bold mb-4">Reiseunterlagen</div>

                  <span title="Reisehinweise">
                    <a
                      class="bg-primary p-1 rounded"
                      :href="
                        'https://www.skr.de/reiseinfos/' +
                        reiseterminObj?.reiseterminkuerzel.replaceAll('-', '').replaceAll('_', '') +
                        '/L/'
                      "
                      target="_blank"
                    >
                      <i class="flaticon-book text-white text-hover-primary font-weight-bold"></i>
                    </a>
                  </span>
                </div>
              </div>
              <b-skeleton v-if="isInitialLoading" height="55px" width="500px"></b-skeleton>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div class="separator separator-solid mt-7 mb-2" v-if="reiseterminObj?.aufgaben?.length > 0"></div>
        <div>
          <AufgabenWidget
            @clickAufgabenWidget="onClickAufgabenWidget"
            :filter="'releaseprozess'"
            :aufgaben="reiseterminObj?.aufgaben || []"
          ></AufgabenWidget>
        </div>
      </div>
      <div v-if="isDoppelterRelease" class="separator separator-solid mt-7 mb-2"></div>
      <div
        v-if="isDoppelterRelease"
        class="bg-light-warning w-100 p-5 rounded mb-2 d-flex align-items-center"
      >
        <b-icon icon="list-check" variant="warning" font-scale="2"></b-icon>
        <div class="mr-2 ml-5">
          Achtung:
          <b>Doppelten</b>
          Release beachten!
        </div>
      </div>
      <div v-else class="separator separator-solid mt-4 mb-2"></div>
      <div
        v-if="isKritischeDestination"
        class="bg-light-danger w-100 p-5 rounded mb-2 d-flex align-items-center"
      >
        <b-icon icon="list-check" variant="danger" font-scale="2"></b-icon>
        <div class="mr-2 ml-5">
          Achtung:
          <b>Kritische</b>
          Destination!
        </div>
      </div>
      <div
        v-if="isKritischeDestination || isDoppelterRelease"
        class="separator separator-solid mb-2 mt-2"
      ></div>

      <ReiseterminViewBesonderheitenBase
        v-if="reiseterminObj"
        :besonderheiten="reiseterminObj.besonderheiten"
        :reisekuerzel="reiseterminObj.reise.reisekuerzel"
        :startdatum="reiseterminObj.startdatum"
        class="mt-4"
      ></ReiseterminViewBesonderheitenBase>
      <b-skeleton v-else width="100%" height="39px"></b-skeleton>
    </div>
    <AufgabeDetailsModal
      @aufgabeBearbeitet="onAufgabeBearbeitet"
      :showAttachments="false"
    ></AufgabeDetailsModal>
  </div>
</template>

<script>
import PaxWidget from '@/components/produkte/reisetermine/pax-widget.vue';
import StateWidget from '@/components/common/state-widget.vue';
import ReiseterminViewBesonderheitenBase from '@/components/produkte/reisetermine/besonderheiten-base.vue';
import ReiseterminViewDetailHeaderMetadata from '@/components/produkte/reisetermine/reisetermin-view-detail-header-metadata.vue';
import { calculateReleaseStartDateDiff } from '@/core/common/helpers/utils';
import AufgabenWidget from '@/components/produkte/reisetermine/aufgaben-widget.vue';
import AufgabeDetailsModal from '@/components/aufgaben/aufgabe-details-modal.vue';
const moment = require('moment');
import ReleaseprozessIcons from '@/components/produkte/reisetermine/releaseprozess-icons.vue';
import { SET_AUFGABE_DETAIL_DATA } from '@/core/aufgaben/stores/aufgaben.module';

export default {
  components: {
    PaxWidget,
    StateWidget,
    ReiseterminViewBesonderheitenBase,
    ReiseterminViewDetailHeaderMetadata,
    ReleaseprozessIcons,
    AufgabenWidget,
    AufgabeDetailsModal,
  },
  props: {
    reiseterminObj: Object,
    isInitialLoading: Boolean,
  },
  data() {
    return {};
  },
  computed: {
    isDoppelterRelease() {
      // TODO
      return this.reiseterminObj?.metadata?.releasefrist?.length > 1;
    },
    isKritischeDestination() {
      return this.reiseterminObj?.metadata?.isKritischeDestination;
    },
    kontaktdatenlisteLink() {
      if (!this.reiseterminObj) {
        return null;
      }
      const startdatumYear = new Date(this.reiseterminObj.startdatum).getFullYear();
      return startdatumYear === 2022
        ? 'https://docs.google.com/spreadsheets/d/1jLZQswDFvOvXLT9qlg44QPP5tDp0mcvt5z0PYof_GG8/edit?usp=sharing'
        : startdatumYear === 2023
        ? 'https://docs.google.com/spreadsheets/d/1ISyLNU-nPPi_M7Yrtm_r55Q789Y_9xdpUlRXZpbW-5k/edit#gid=0'
        : startdatumYear === 2024
        ? 'https://docs.google.com/spreadsheets/d/1mf6SBNPv8NNBUulHYNA1XGyKcPxrp3nsRz7Nyx6Har0/edit#gid=0'
        : null;
    },
    releaseStartDateDiff() {
      return calculateReleaseStartDateDiff(this.reiseterminObj.releasedatum, this.reiseterminObj.startdatum);
    },
    reisezeitraum() {
      if (!this.reiseterminObj) return '-';
      const reisedauer = this.reiseterminObj?.metadata?.reisedauer
        ? this.reiseterminObj?.metadata?.reisedauer - 1
        : 0;
      const momentUntilDate = moment(this.reiseterminObj.abreisedatum).add(reisedauer, 'days');
      return this.reiseterminObj?.abreisedatum
        ? `${this.$options.filters.date(this.reiseterminObj.abreisedatum)} -  ${this.$options.filters.date(
            momentUntilDate
          )}`
        : '-';
    },
    abreisedatum() {
      return this.reiseterminObj?.abreisedatum
        ? this.$options.filters.date(this.reiseterminObj.abreisedatum)
        : '-';
    },
    versandReiseUnterlagen() {
      return this.reiseterminObj?.unterlagenversandDatum
        ? this.$options.filters.date(this.reiseterminObj.unterlagenversandDatum)
        : '-';
    },
  },
  methods: {
    onAufgabeBearbeitet(response) {
      this.$emit('aufgabeBearbeitet', response);
    },
    onClickAufgabenWidget(aufgabe) {
      this.$store.commit(SET_AUFGABE_DETAIL_DATA, aufgabe);
      this.$root.$emit('bv::show::modal', 'modalAufgabeDetails');
    },
    getStateGerman(value) {
      return value !== null && value !== undefined ? this.$t('REISETERMINE.states.' + value) : value;
    },
  },
};
</script>
<style>
.tooltip {
  opacity: 1 !important;
}

.custom-tooltip > .tooltip-inner {
  max-width: none;
}
</style>
