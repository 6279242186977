<template>
  <b-table
    :items="reiseterminData"
    :fields="reiseterminReleaseprozessFields"
    responsive
    hover
    style="border: 1px solid rgb(0, 0, 0, 0.1); border-radius: 5px; margin-top: 12px"
    head-variant="light"
    @row-clicked="rowClicked"
  >
    <template v-slot:cell(reiseterminkuerzel)="data">
      <div style="width: 80px">
        {{ data.value }}
      </div>
    </template>
    <template v-slot:cell(state)="row">
      <b-badge>{{ getStateGerman(row.item.state) }}</b-badge>
    </template>
    <template v-slot:cell(vorgangReleaseprozess.teilnehmerErledigt)="row">
      <div class="position-relative">
        <i
          :class="
            releaseprozessIconsColors('teilnehmerErledigt', row.item.vorgangReleaseprozess.teilnehmerErledigt)
          "
          class="fas fa-user-check fa-lg"
        ></i>
        <i
          v-if="row.item.vorgangReleaseprozess.configurationFulfilledForAllTeilnehmer === false"
          class="fas fa-asterisk text-danger icon-sm position-absolute translate-middle ml-1"
          title="Passdaten nicht vollständig"
        ></i>
      </div>
    </template>
    <template v-slot:cell(vorgangReleaseprozess.reiseleistungListenversandErledigt)="row">
      <i
        :class="
          releaseprozessIconsColors(
            'reiseleistungListenversandErledigt',
            row.item.vorgangReleaseprozess.reiseleistungListenversandErledigt
          )
        "
        class="fas fa-suitcase fa-lg"
      ></i>
    </template>
    <template v-slot:cell(vorgangReleaseprozess.fluegePassend)="row">
      <i
        :class="
          getFluegePassend(
            row.item.vorgangReleaseprozess.fluegePassend,
            row.item.vorgangReleaseprozess.flugticketsAusgestellt
          )
        "
        class="fas fa-plane fa-lg"
      ></i>
    </template>
    <template v-slot:cell(vorgangReleaseprozess.transferplanungErledigt)="row">
      <i
        :class="
          releaseprozessIconsColors(
            'transferplanungErledigt',
            row.item.vorgangReleaseprozess.transferplanungErledigt
          )
        "
        class="fas fa-car fa-lg"
      ></i>
    </template>
    <template v-slot:cell(reiseunterlagenGeprueft)="row">
      <i
        :class="row.item.reiseunterlagenGeprueft ? 'text-success' : 'text-danger'"
        class="fas fa-list-alt fa-lg"
      ></i>
    </template>
    <template v-slot:cell(transferplanungGeprueft)="row">
      <i
        :class="row.item.transferplanungGeprueft ? 'text-success' : 'text-danger'"
        class="fas fa-bus fa-lg"
      ></i>
    </template>
    <template v-slot:cell(garantiert)="row">
      <i v-if="row.item.garantiert" class="fas fa-check text-success"></i>
      <i v-else class="fas fa-times text-danger"></i>
    </template>

    <template v-slot:cell(metadata.isKritischeDestination)="row">
      <i v-if="row.item.metadata.isKritischeDestination === true" class="fas fa-check text-success"></i>
      <i v-else-if="row.item.metadata.isKritischeDestination === false" class="fas fa-times text-danger"></i>
      <div v-else>-</div>
    </template>
    <template v-slot:cell(pax)="row">
      <PaxWidget :paxObject="row.item.pax" :kalkPax="row.item.metadata.kalkPax"></PaxWidget>
    </template>
    <template v-slot:cell(commentCount)="row">
      <span>{{ row.item.comments.length }}</span>
    </template>
  </b-table>
</template>
<script>
import { releaseprozessIconsColors, formatDateWithoutHours } from '@/core/common/helpers/utils';
import { format, sub } from 'date-fns';
import PaxWidget from '@/components/produkte/reisetermine/pax-widget.vue';

export default {
  props: {
    reiseterminData: { type: Array, required: true },
  },
  components: {
    PaxWidget,
  },
  data() {
    return {
      reiseterminReleaseprozessFields: [
        {
          tdClass: 'align-middle',
          key: 'reiseterminkuerzel',
          label: 'Reisekürzel',
          formatter: value => value?.substring(0, 6),
        },
        { tdClass: 'align-middle', key: 'state', label: 'Status' },
        {
          tdClass: 'align-middle',
          key: 'startdatum',
          label: 'Startdatum',
          formatter: value => {
            return this.formatDateWithoutHours(value);
          },
        },
        {
          tdClass: 'align-middle',
          key: 'releasedatum',
          label: 'Releasedatum',
          formatter: value => {
            return this.formatDateWithoutHours(value);
          },
        },
        {
          tdClass: 'align-middle',
          key: 'unterlagenversandDatum',
          label: 'Versand',
          formatter: value => {
            return this.formatDateWithoutHours(value);
          },
        },
        {
          tdClass: 'align-middle',
          key: 'vorgangReleaseprozess.teilnehmerErledigt',
          label: 'TD',
        },
        {
          tdClass: 'align-middle',
          key: 'vorgangReleaseprozess.reiseleistungListenversandErledigt',
          label: 'GL',
        },
        {
          tdClass: 'align-middle',
          key: 'vorgangReleaseprozess.fluegePassend',
          label: 'F',
        },
        {
          tdClass: 'align-middle',
          key: 'vorgangReleaseprozess.transferplanungErledigt',
          label: 'TP',
        },
        {
          tdClass: 'align-middle',
          key: 'reiseunterlagenGeprueft',
          label: 'RTE',
        },
        {
          tdClass: 'align-middle',
          key: 'transferplanungGeprueft',
          label: 'FTP',
        },
        {
          tdClass: 'align-middle',
          key: 'garantiert',
          label: 'Gar.',
        },
        {
          tdClass: 'align-middle',
          key: 'pax',
          label: 'PAX',
        },
        { tdClass: 'align-middle', key: 'metadata.isKritischeDestination', label: 'Krit. Dest.' },
        {
          tdClass: 'align-middle',
          key: 'commentCount',
          label: 'Kommentare',
        },
      ],
    };
  },
  methods: {
    formatDateWithoutHours,
    releaseprozessIconsColors,
    getStateGerman(value) {
      return value !== null && value !== undefined ? this.$t('REISETERMINE.states.' + value) : value;
    },
    rowClicked(item) {
      window.open('/#/reisetermine/' + item.id + '/releasepruefung', '_blank');
    },
    getFluegePassend(fluegePassend, flugticketsAusgestellt) {
      return fluegePassend && flugticketsAusgestellt
        ? 'text-success'
        : fluegePassend || flugticketsAusgestellt
        ? 'text-warning'
        : 'text-danger';
    },
  },
};
</script>
