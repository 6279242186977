<template>
  <div class="d-flex flex-wrap align-items-baseline mr-10">
    <div class="mr-6 d-flex flex-column align-items-baseline">
      <div class="font-weight-bold mb-4">{{ $t('REISETERMINE.METADATA.overnightoutbound') }}</div>
      <span class="rounded d-flex text-nowrap p-2 bg-light-primary text-uppercase text-primary">
        {{ (metadata && metadata.overnightOutbound) || '-' }}</span
      >
    </div>
    <div class="mr-6 d-flex flex-column align-items-baseline">
      <div class="font-weight-bold mb-4">{{ $t('REISETERMINE.METADATA.overnightinbound') }}</div>
      <span class="rounded d-flex text-nowrap p-2 bg-light-primary text-uppercase text-primary">
        {{ (metadata && metadata.overnightInbound) || '-' }}</span
      >
    </div>
    <div class="mr-6 d-flex flex-column align-items-baseline">
      <div class="font-weight-bold mb-4">{{ $t('REISETERMINE.METADATA.hotelstartVorLandung') }}</div>
      <span class="rounded d-flex text-nowrap p-2 bg-light-primary text-uppercase text-primary">
        <i
          :class="
            metadata && metadata.hotelstartVorLandung
              ? 'fas fa-check text-success'
              : 'fas fa-times text-danger'
          "
      /></span>
    </div>
    <div class="mr-6 d-flex flex-column align-items-baseline">
      <div class="font-weight-bold mb-4">{{ $t('REISETERMINE.METADATA.rueckflugNachHotelende') }}</div>
      <span class="rounded d-flex text-nowrap p-2 bg-light-primary text-uppercase text-primary">
        <i
          :class="
            metadata && metadata.rueckflugNachHotelende
              ? 'fas fa-check text-success'
              : 'fas fa-times text-danger'
          "
      /></span>
    </div>
    <div class="mr-6 d-flex flex-column align-items-baseline">
      <div class="font-weight-bold mb-4">{{ $t('REISETERMINE.METADATA.ziellandZielflughaefen') }}</div>
      <span class="rounded d-flex text-nowrap p-2 bg-light-primary text-uppercase text-primary">
        {{ getFlughaefenString(metadata, 'ziellandZielflughaefen') }}
      </span>
    </div>
    <div class="mr-6 d-flex flex-column align-items-baseline">
      <div class="font-weight-bold mb-4">{{ $t('REISETERMINE.METADATA.ziellandAbflughaefen') }}</div>
      <span class="rounded d-flex text-nowrap p-2 bg-light-primary text-uppercase text-primary">
        {{ getFlughaefenString(metadata, 'ziellandAbflughaefen') }}
      </span>
    </div>
    <div class="mr-6 d-flex flex-column align-items-baseline">
      <TransferszenarioToolTip target="transferszenario-info" />
      <div id="transferszenario-info" class="font-weight-bold mb-4">Transferszenario Hin</div>
      <span class="rounded d-flex text-nowrap p-2 bg-light-primary text-uppercase text-primary">
        {{ (metadata && metadata.transferszenarioHin) || '-' }}</span
      >
    </div>
    <div class="mr-6 d-flex flex-column align-items-baseline">
      <TransferszenarioToolTip target="transferszenario-info" />
      <div id="transferszenario-info" class="font-weight-bold mb-4">Transferszenario Rück</div>
      <span class="rounded d-flex text-nowrap p-2 bg-light-primary text-uppercase text-primary">
        {{ (metadata && metadata.transferszenarioRueck) || '-' }}</span
      >
    </div>
  </div>
</template>

<script>
import TransferszenarioToolTip from '@/components/produkte/reisetermine/transferszenario-tooltip.vue';
import { getFlughaefenString } from '@/core/common/helpers/utils';

export default {
  components: {
    TransferszenarioToolTip,
  },
  props: {
    metadata: { type: Object },
  },
  data() {
    return {
      isEditing: false,
    };
  },
  methods: { getFlughaefenString },
};
</script>
