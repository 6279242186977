<template>
  <div
    class="p-4 card"
    :class="
      editCommentId === comment.id
        ? 'comment-editing'
        : replyCommentId === comment.id
        ? 'comment-reply'
        : 'comment'
    "
    @mouseover="isHover = true"
    @mouseleave="isHover = false"
  >
    <b-overlay :show="commentIsBusy">
      <!-- Author/Role ---- Time  -->
      <div class="d-flex justify-content-between align-items-center mb-1">
        <span class="font-weight-bolder mr-2">{{ $store.getters.getUsernameById(comment.creator) }}</span>
        <div class="d-flex align-items-center">
          <span
            @click="$emit('comment-delete', comment.id)"
            v-if="comment.creator === currentUserId"
            class="btn btn-clean btn-xs btn-icon"
            title="Löschen"
          >
            <i class="flaticon2-rubbish-bin icon-1x"></i>
          </span>
          <span
            v-if="currentUserId === comment.creator"
            class="btn btn-clean btn-xs btn-icon"
            @click="$emit('comment-edit', comment.id, comment.text)"
            title="Bearbeiten"
          >
            <i class="flaticon-edit icon-1x"></i>
          </span>
          <span
            v-if="!isAnswer"
            class="mr-1 btn btn-clean btn-xs btn-icon"
            title="Antworten"
            @click="$emit('comment-reply', comment.id)"
          >
            <i class="flaticon-reply icon-1x"></i>
          </span>
          <div class="font-weight-bold text-muted">
            {{ comment.createdDateUtc | dateTime }}
          </div>
        </div>
      </div>

      <!-- Comment HTML -->
      <div style="font-size: 14px" v-html="comment.text" :style="cssProps"></div>
      <template v-if="isAnswersShowing">
        <Comment
          class="mb-1"
          v-for="answer in filteredAnswers"
          :key="answer.id"
          :comment="answer"
          @comment-edit="$emit('comment-edit', answer.id, answer.text)"
          @comment-delete="$emit('comment-delete', answer.id)"
          :isAnswer="true"
        ></Comment>
      </template>
      <div
        v-if="!isAnswer && isHover"
        class="shadow-sm"
        style="
          position: absolute;
          right: 0;
          bottom: 0;
          background-color: white;
          border: 1px solid rgba(0, 0, 0, 0.1);
          border-radius: 5px;
        "
      >
        <button
          v-for="(emoji, id) in reactionEmojis"
          @click="clickEmoji(emoji)"
          :key="'emoji' + id"
          class="emoji-button p-2"
          style="border-radius: 5px"
        >
          {{ emoji }}
        </button>
      </div>

      <div v-if="comment.answers && filteredAnswers.length > 0" class="separator separator-solid"></div>
      <div class="d-flex font-size-sm">
        <span
          class="p-1 mt-1 cursor-pointer text-hover-primary rounded"
          @click.stop="isAnswersShowing = !isAnswersShowing"
          v-if="comment.answers && filteredAnswers.length > 0"
        >
          {{
            isAnswersShowing
              ? 'Antworten verstecken'
              : '+' + filteredAnswers.length + ' Antwort' + (filteredAnswers.length === 1 ? '' : 'en')
          }}
        </span>
      </div>

      <div v-if="!isAnswer && getReactions.length > 0" class="mt-3">
        <button
          v-for="(obj, emoji) in getCountedAndSortedReaction"
          @click="clickEmoji(emoji)"
          :key="'emoji-count' + emoji + ' ' + comment.id"
          :id="'emoji-count' + emoji + ' ' + comment.id"
          style="position: relative"
          class="reaction-button mr-1 px-2"
          :class="{ 'clicked-from-user': isClickedFromUser(emoji) }"
        >
          {{ emoji }}
          <b>{{ obj.count }}</b>

          <b-tooltip
            :target="'emoji-count' + emoji + ' ' + comment.id"
            placement="top"
            :delay="0"
            triggers="hover"
          >
            <b>{{ getReactionTooltip(obj) }}</b>
          </b-tooltip>
        </button>
      </div>
    </b-overlay>
  </div>
</template>
<script>
import { mapGetters, mapState } from 'vuex';
import Comment from '@/components/aufgaben/comment.vue';
import { SAVE_REACTION, DELETE_REACTION } from '@/core/aufgaben/stores/aufgaben.module';
export default {
  name: 'Comment',
  props: {
    comment: { type: Object },
    isAnswer: { type: Boolean, default: false },
  },
  components: { Comment },
  data() {
    return {
      commentIsBusy: false,
      isExpanded: false,
      isAnswersShowing: false,
      reactionEmojis: ['👍', '👎', '❤️', '🎉', '😃', '😟'],
      isHover: false,
    };
  },
  computed: {
    ...mapGetters(['currentUserId']),
    ...mapState({
      editCommentId: state => state.aufgaben.comments.editCommentId,
      replyCommentId: state => state.aufgaben.comments.replyCommentId,
    }),
    filteredAnswers() {
      return this.comment.answers.filter(item => !this.reactionEmojis.includes(item.text));
    },
    getReactions() {
      const reactions = this.comment.answers?.filter(item => this.reactionEmojis.includes(item.text));
      return reactions;
    },
    hasUserReacted() {
      const reactions = this.getReactions;
      return reactions.find(item => item.creator === this.currentUserId);
    },
    getCountedAndSortedReaction() {
      const reactions = this.comment.answers?.filter(item => this.reactionEmojis.includes(item.text));
      const reactionsSortedByDate = reactions.sort(
        (a, b) => new Date(a.createdDateUtc) - new Date(b.createdDateUtc)
      );
      const countedReactions = reactionsSortedByDate.reduce((acc, curr) => {
        if (acc.hasOwnProperty(curr.text)) {
          const addedCount = acc[curr.text].count + 1;
          const shortName = this.getShortenedNameFromId(curr.creator);
          const peopleReactingNew = [shortName, ...acc[curr.text].peopleReacting];
          acc[curr.text] = { count: addedCount, peopleReacting: peopleReactingNew };
        } else {
          const shortName = this.getShortenedNameFromId(curr.creator);
          acc[curr.text] = { count: 1, peopleReacting: [shortName] };
        }
        return acc;
      }, {});
      const countsArray = Object.entries(countedReactions).sort((a, b) => b.count - a.count);
      const sortedCounts = Object.fromEntries(countsArray);
      return sortedCounts;
    },
    cssProps() {
      return {
        '--background-color-mentions': this.comment.text.includes(this.currentUserId) ? '#3699FF' : '#c6d1da',
        '--color-mentions': this.comment.text.includes(this.currentUserId) ? 'white' : '',
      };
    },
  },
  methods: {
    getShortenedNameFromId(id) {
      const fullName = this.$store.getters.getUsernameById(id);
      const splitName = fullName?.split(' ');
      // the last entity from split is the last name (in case of multiple names "de las hereras")
      const splitNameLength = splitName.length - 1;
      const lastNameShortened = splitName[splitNameLength].substring(0, 1) + '.';
      const shortName = splitName[0] + ' ' + lastNameShortened;
      return shortName;
    },
    getReactionTooltip(obj) {
      if (obj.count > 2) {
        const lastReactingPerson = obj.peopleReacting[0];
        return `${lastReactingPerson} und ${obj.count - 1} weitere`;
      } else if (obj.count === 2) {
        return `${obj.peopleReacting[0]}, ${obj.peopleReacting[1]}`;
      } else {
        return `${obj.peopleReacting[0]}`;
      }
    },
    isClickedFromUser(emoji) {
      const reactions = this.getReactions;
      const filteredReactions = reactions.filter(
        item => item.text === emoji && item.creator === this.currentUserId
      );
      return filteredReactions.length > 0;
    },
    async clickEmoji(emoji) {
      this.commentIsBusy = true;
      if (this.hasUserReacted) {
        const reactionComment = this.getReactions.find(item => item.creator === this.currentUserId);
        // check if it is the same emoji, just delete the reaction
        if (reactionComment.text === emoji) {
          this.$store
            .dispatch(DELETE_REACTION, {
              commentId: reactionComment.id,
            })
            .then(res => this.$emit('reactionMade', res));
        }
        // if it is a different reaction, save new one, delete old one
        else {
          await this.$store.dispatch(DELETE_REACTION, {
            commentId: reactionComment.id,
          });
          await this.$store
            .dispatch(SAVE_REACTION, { text: emoji, replyCommentId: this.comment.id })
            .then(res => this.$emit('reactionMade', res));
        }
      } else {
        await this.$store
          .dispatch(SAVE_REACTION, { text: emoji, replyCommentId: this.comment.id })
          .then(res => this.$emit('reactionMade', res));
      }
      this.commentIsBusy = false;
    },
  },
};
</script>
<style scoped>
.comment {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
}
.comment-editing {
  background-color: rgba(4, 96, 255, 0.13);
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
}
.comment-reply {
  background-color: #fdfd96;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
}
.emoji-button,
.reaction-button {
  background: none;
  color: inherit;
  border: none;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  font-size: 12px;
}
.emoji-button:hover,
.reaction-button:hover {
  background-color: #babfc7;
}
.reaction-button {
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.1);
}
.clicked-from-user {
  background-color: rgba(222, 236, 249);
  border: 1px solid rgba(0, 120, 212, 1);
}
:deep(.mention) {
  background-color: var(--background-color-mentions);
  color: var(--color-mentions);
  padding: 1px 6px;
  border-radius: 8px;
  font-weight: 500;
  margin-right: 4px;
}
</style>
