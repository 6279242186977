<template>
  <div>
    <div v-if="isFomAufgabe">
      <VorgangVerlinkungenFom :vorgangData="vorgangData" />
    </div>
    <div v-else>
      <VorgangVerlinkungenBasic :vorgangData="vorgangData" />
    </div>
  </div>
</template>
<script>
import VorgangVerlinkungenBasic from '@/components/aufgaben/verlinkungen/vorgang-verlinkungen-basic.vue';
import VorgangVerlinkungenFom from '@/components/aufgaben/verlinkungen/vorgang-verlinkungen-fom.vue';

export default {
  components: {
    VorgangVerlinkungenBasic,
    VorgangVerlinkungenFom,
  },
  props: {
    vorgangData: { type: Array, required: true },
    isFomAufgabe: { type: Boolean, required: true },
  },
};
</script>
