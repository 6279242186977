var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-table',{staticStyle:{"border":"1px solid rgb(0, 0, 0, 0.1)","border-radius":"5px","margin-top":"12px"},attrs:{"items":_vm.reiseterminData,"fields":_vm.reiseterminReleaseprozessFields,"responsive":"","hover":"","head-variant":"light"},on:{"row-clicked":_vm.rowClicked},scopedSlots:_vm._u([{key:"cell(reiseterminkuerzel)",fn:function(data){return [_c('div',{staticStyle:{"width":"80px"}},[_vm._v(" "+_vm._s(data.value)+" ")])]}},{key:"cell(state)",fn:function(row){return [_c('b-badge',[_vm._v(_vm._s(_vm.getStateGerman(row.item.state)))])]}},{key:"cell(vorgangReleaseprozess.teilnehmerErledigt)",fn:function(row){return [_c('div',{staticClass:"position-relative"},[_c('i',{staticClass:"fas fa-user-check fa-lg",class:_vm.releaseprozessIconsColors('teilnehmerErledigt', row.item.vorgangReleaseprozess.teilnehmerErledigt)}),(row.item.vorgangReleaseprozess.configurationFulfilledForAllTeilnehmer === false)?_c('i',{staticClass:"fas fa-asterisk text-danger icon-sm position-absolute translate-middle ml-1",attrs:{"title":"Passdaten nicht vollständig"}}):_vm._e()])]}},{key:"cell(vorgangReleaseprozess.reiseleistungListenversandErledigt)",fn:function(row){return [_c('i',{staticClass:"fas fa-suitcase fa-lg",class:_vm.releaseprozessIconsColors(
          'reiseleistungListenversandErledigt',
          row.item.vorgangReleaseprozess.reiseleistungListenversandErledigt
        )})]}},{key:"cell(vorgangReleaseprozess.fluegePassend)",fn:function(row){return [_c('i',{staticClass:"fas fa-plane fa-lg",class:_vm.getFluegePassend(
          row.item.vorgangReleaseprozess.fluegePassend,
          row.item.vorgangReleaseprozess.flugticketsAusgestellt
        )})]}},{key:"cell(vorgangReleaseprozess.transferplanungErledigt)",fn:function(row){return [_c('i',{staticClass:"fas fa-car fa-lg",class:_vm.releaseprozessIconsColors(
          'transferplanungErledigt',
          row.item.vorgangReleaseprozess.transferplanungErledigt
        )})]}},{key:"cell(reiseunterlagenGeprueft)",fn:function(row){return [_c('i',{staticClass:"fas fa-list-alt fa-lg",class:row.item.reiseunterlagenGeprueft ? 'text-success' : 'text-danger'})]}},{key:"cell(transferplanungGeprueft)",fn:function(row){return [_c('i',{staticClass:"fas fa-bus fa-lg",class:row.item.transferplanungGeprueft ? 'text-success' : 'text-danger'})]}},{key:"cell(garantiert)",fn:function(row){return [(row.item.garantiert)?_c('i',{staticClass:"fas fa-check text-success"}):_c('i',{staticClass:"fas fa-times text-danger"})]}},{key:"cell(metadata.isKritischeDestination)",fn:function(row){return [(row.item.metadata.isKritischeDestination === true)?_c('i',{staticClass:"fas fa-check text-success"}):(row.item.metadata.isKritischeDestination === false)?_c('i',{staticClass:"fas fa-times text-danger"}):_c('div',[_vm._v("-")])]}},{key:"cell(pax)",fn:function(row){return [_c('PaxWidget',{attrs:{"paxObject":row.item.pax,"kalkPax":row.item.metadata.kalkPax}})]}},{key:"cell(commentCount)",fn:function(row){return [_c('span',[_vm._v(_vm._s(row.item.comments.length))])]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }