import { render, staticRenderFns } from "./comment-input-field.vue?vue&type=template&id=11af0864&scoped=true"
import script from "./comment-input-field.vue?vue&type=script&lang=js"
export * from "./comment-input-field.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "11af0864",
  null
  
)

export default component.exports