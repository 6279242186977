<template>
  <div>
    <b-table
      style="border: 1px solid rgb(0, 0, 0, 0.1); border-radius: 5px; margin-top: 12px; white-space: nowrap"
      :items="reiseterminData"
      :fields="reiseterminFields"
      responsive
      hover
      @row-clicked="rowClicked"
      head-variant="light"
    >
      <template v-slot:cell(reise.prioritaet)="data">
        <b-badge>{{ getValueByPriority(data.value) }}</b-badge>
      </template>
      <template v-slot:cell(pax)="row">
        <PaxWidget :paxObject="row.item.pax" :kalkPax="row.item.metadata.kalkPax"></PaxWidget>
      </template>
    </b-table>
  </div>
</template>
<script>
import configPriority from '@/core/produkte/reisen/priority.config.json';
import { formatDateWithoutHours } from '@/core/common/helpers/utils';
import PaxWidget from '@/components/produkte/reisetermine/pax-widget.vue';

export default {
  components: {
    PaxWidget,
  },
  props: {
    reiseterminData: { type: Array, required: true },
  },
  data() {
    return {
      reiseterminFields: [
        {
          tdClass: 'align-middle',
          key: 'reise.reisekuerzel',
          label: 'Reisekürzel',
        },
        {
          tdClass: 'align-middle',
          key: 'reise.prioritaet',
          label: 'Priorität',
        },
        {
          tdClass: 'align-middle',
          key: 'abreisedatum',
          label: 'Abreisedatum',
          formatter: value => this.formatDateWithoutHours(value),
        },
        {
          tdClass: 'align-middle',
          key: 'startdatum',
          label: 'Startdatum',
          formatter: value => this.formatDateWithoutHours(value),
        },
        {
          tdClass: 'align-middle',
          key: 'pax',
          label: 'PAX',
        },
        {
          tdClass: 'align-middle',
          key: 'metadata.transferszenario',
          label: 'Transferszenario',
          formatter: value => value?.substring(0, 2),
        },
        {
          tdClass: 'align-middle',
          key: 'pax',
          label: 'PAX',
        },
        {
          tdClass: 'align-middle',
          key: 'thMain',
          label: 'TH Langstrecke',
          formatter: value => (value ? this.$options.filters.currency(value) : ''),
        },
        {
          tdClass: 'align-middle',
          key: 'thAlleAddOns',
          label: 'TH Inlandsflug',
          formatter: value => (value ? this.$options.filters.currency(value) : ''),
        },
        {
          tdClass: 'align-middle',
          key: 'thGesamt',
          label: 'TH Gesamt',
          formatter: value => (value ? this.$options.filters.currency(value) : ''),
        },
      ],
    };
  },
  methods: {
    formatDateWithoutHours,
    rowClicked(item) {
      window.open('/#/reisetermine/' + item.id, '_blank');
    },
    getValueByPriority(priority) {
      return configPriority[priority].value;
    },
  },
};
</script>
