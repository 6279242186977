<template>
  <b-table
    style="border: 1px solid rgb(0, 0, 0, 0.1); border-radius: 5px; margin-top: 12px"
    :items="reiseterminData"
    :fields="reiseterminFields"
    responsive
    hover
    @row-clicked="rowClicked"
    head-variant="light"
  >
    <template v-slot:cell(reiseterminkuerzel)="data">
      <div style="width: 150px">
        {{ data.value }}
      </div>
    </template>
    <template v-slot:cell(reisezeitraum)="row">
      <div style="width: 150px">
        {{ formatDateWithoutHours(row.item.abreisedatum) }} -
        {{ formatDateWithoutHours(row.item.enddatum) }}
      </div>
    </template>
    <template v-slot:cell(pax)="row">
      <PaxWidget :paxObject="row.item.pax" :kalkPax="row.item.metadata.kalkPax"></PaxWidget>
    </template>
    <template v-slot:cell(ziellandAbUndZielflughafen)="row">
      {{ row.item.metadata.ziellandAbflughaefen?.map(fh => fh.flughafenIataCode).join(', ') }}
      /
      {{ row.item.metadata.ziellandZielflughaefen?.map(fh => fh.flughafenIataCode).join(', ') }}
    </template>
    <template v-slot:cell(metadata.hotelstartVorLandung)="row">
      <i v-if="row.item.metadata.hotelstartVorLandung" class="fas fa-check text-success"></i>
      <i v-else class="fas fa-times text-danger"></i>
    </template>
    <template v-slot:cell(metadata.rueckflugNachHotelende)="row">
      <i v-if="row.item.metadata.rueckflugNachHotelende" class="fas fa-check text-success"></i>
      <i v-else class="fas fa-times text-danger"></i>
    </template>
    <template v-slot:cell(abgesagt)="row">
      <i v-if="row.item.abgesagt" class="fas fa-check text-success"></i>
      <i v-else class="fas fa-times text-danger"></i>
    </template>
    <template v-slot:cell(garantiert)="row">
      <i v-if="row.item.garantiert" class="fas fa-check text-success"></i>
      <i v-else class="fas fa-times text-danger"></i>
    </template>
    <template v-slot:cell(stopSale)="row">
      <i v-if="row.item.stopSale" class="fas fa-check text-success"></i>
      <i v-else class="fas fa-times text-danger"></i>
    </template>
  </b-table>
</template>
<script>
import { formatDateWithoutHours } from '@/core/common/helpers/utils';
import PaxWidget from '@/components/produkte/reisetermine/pax-widget.vue';

export default {
  props: { reiseterminData: { type: Array, required: true } },
  components: {
    PaxWidget,
  },
  data() {
    return {
      reiseterminFields: [
        {
          tdClass: 'align-middle',
          key: 'reiseterminkuerzel',
          label: 'Reiseterminkürzel',
        },
        {
          tdClass: 'align-middle',
          key: 'startdatum',
          label: 'Startdatum',
          formatter: value => {
            return this.formatDateWithoutHours(value);
          },
        },
        {
          tdClass: 'align-middle',
          key: 'reisezeitraum',
        },
        {
          tdClass: 'align-middle',
          key: 'metadata.reisedauer',
          label: 'Reisedauer',
          formatter: value => (value ? value + ' Tage' : '-'),
        },
        {
          tdClass: 'align-middle',
          key: 'releasedatum',
          formatter: value => {
            return this.formatDateWithoutHours(value);
          },
        },
        { tdClass: 'align-middle', key: 'pax' },
        {
          tdClass: 'align-middle',
          key: 'abgesagt',
          label: 'Abgesagt',
        },
        {
          tdClass: 'align-middle',
          key: 'garantiert',
          label: 'Garantiert',
        },
        {
          tdClass: 'align-middle',
          key: 'stopSale',
          label: 'Stop Sale',
          thStyle: { whiteSpace: 'nowrap' },
        },
        {
          tdClass: 'align-middle',
          key: 'metadata.overnightOutbound',
          label: 'OO',
        },
        {
          tdClass: 'align-middle',
          key: 'metadata.overnightInbound',
          label: 'OI',
        },
        {
          tdClass: 'align-middle',
          key: 'metadata.hotelstartVorLandung',
          label: 'Hotelstart v. Landung',
          thStyle: { whiteSpace: 'nowrap' },
        },
        {
          tdClass: 'align-middle',
          key: 'metadata.rueckflugNachHotelende',
          label: 'Rückflug n. Hotelende',
          thStyle: { whiteSpace: 'nowrap' },
        },
        {
          tdClass: 'align-middle',
          key: 'ziellandAbUndZielflughafen',
          label: 'Zielland Ab-/Zielfh.',
          thStyle: { whiteSpace: 'nowrap' },
        },
        {
          tdClass: 'align-middle',
          key: 'metadata.transferszenario',
          label: 'TS',
          formatter: value => value?.substring(0, 2),
        },
      ],
    };
  },
  methods: {
    formatDateWithoutHours,
    rowClicked(item) {
      window.open('/#/reisetermine/' + item.id, '_blank');
    },
  },
};
</script>
