var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('portal',{attrs:{"to":"subheader-left"}},[(_vm.reiseterminId)?_c('ul',{staticClass:"nav nav-light-primary nav-bold nav-pills py-2"},[_c('router-link',{attrs:{"custom":"","to":{ name: 'reisetermine-view', params: { reiseterminId: _vm.reiseterminId } }},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"nav-link px-xs-3 px-lg-4",class:{ 'menu-item-active': isActive, active: _vm.$route.name === 'reisetermine-view' },attrs:{"href":href},on:{"click":navigate}},[_c('span',[_vm._v("Details")])])])]}}],null,false,2959682989)}),_c('router-link',{attrs:{"custom":"","to":{ name: 'reisetermin-view-releasepruefung', params: { reiseterminId: _vm.reiseterminId } }},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"nav-link px-xs-3 px-lg-4",class:{
              'menu-item-active': isActive,
              active: _vm.$route.name === 'reisetermin-view-releasepruefung',
            },attrs:{"href":href},on:{"click":navigate}},[_c('span',[_vm._v("Releaseprüfung")])])])]}}],null,false,3598382029)}),_c('router-link',{attrs:{"custom":"","to":{ name: 'reisetermine-view-history', params: { reiseterminId: _vm.reiseterminId } }},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"nav-link px-xs-3 px-lg-4",class:{ 'menu-item-active': isActive, active: _vm.$route.name === 'reisetermine-view-history' },attrs:{"href":href},on:{"click":navigate}},[_c('span',[_vm._v("Verlauf")])])])]}}],null,false,3604951231)}),_c('router-link',{attrs:{"custom":"","to":{ name: 'reisetermin-view-einstellungen', params: { reiseterminId: _vm.reiseterminId } }},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"nav-link px-xs-3 px-lg-4",class:{
              'menu-item-active': isActive,
              active: _vm.$route.name === 'reisetermin-view-einstellungen',
            },attrs:{"href":href},on:{"click":navigate}},[_c('span',[_vm._v("Einstellungen")])])])]}}],null,false,4014660449)})],1):_vm._e()]),_c('div',{ref:"intersectWatcher"}),_c('ReiseterminViewDetailHeader',{attrs:{"isInitialLoading":_vm.isInitialLoading,"reiseterminObj":_vm.reiseterminObj},on:{"aufgabeBearbeitet":_vm.onAufgabeBearbeitet}}),(!_vm.$store.state.userSettingsLocal.alwaysHideReiseterminCompactHeader)?_c('ReiseterminViewDetailHeaderCompact',{directives:[{name:"show",rawName:"v-show",value:(_vm.showCompactHeader),expression:"showCompactHeader"}],attrs:{"isInitialLoading":_vm.isInitialLoading,"reiseterminObj":_vm.reiseterminObj},on:{"triggerPressed":_vm.loadData}}):_vm._e(),_c('ReiseterminViewCardSheetdata',{attrs:{"reiseterminObj":_vm.reiseterminObj}}),_c('router-view',{attrs:{"reiseterminObj":_vm.reiseterminObj},on:{"updated-vorgang":_vm.loadData,"releaseprocess-updated":_vm.releaseProzessVorgaengeUpdate}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }