<template>
  <b-table
    style="border: 1px solid rgb(0, 0, 0, 0.1); border-radius: 5px; margin-top: 12px"
    :items="reiseterminData"
    :fields="fields"
    responsive
    hover
    @row-clicked="rowClicked"
    head-variant="light"
  >
    <template v-slot:cell(reiseterminkuerzel)="data">
      <div style="width: 150px">
        {{ data.value }}
      </div>
    </template>
    <template v-slot:cell(reisezeitraum)="row">
      <div style="width: 150px">
        {{ formatDateWithoutHours(row.item.abreisedatum) }} -
        {{ formatDateWithoutHours(row.item.enddatum) }}
      </div>
    </template>
    <template v-slot:cell(pax)="row">
      <PaxWidget :paxObject="row.item.pax" :kalkPax="row.item.metadata.kalkPax"></PaxWidget>
    </template>
    <template v-slot:cell(ziellandAbUndZielflughafen)="row">
      {{ row.item.metadata.ziellandAbflughaefen?.map(fh => fh.flughafenIataCode).join(', ') }}
      /
      {{ row.item.metadata.ziellandZielflughaefen?.map(fh => fh.flughafenIataCode).join(', ') }}
    </template>
    <template v-slot:cell(metadata.hotelstartVorLandung)="row">
      <i v-if="row.item.metadata.hotelstartVorLandung" class="fas fa-check text-success"></i>
      <i v-else class="fas fa-times text-danger"></i>
    </template>
    <template v-slot:cell(metadata.rueckflugNachHotelende)="row">
      <i v-if="row.item.metadata.rueckflugNachHotelende" class="fas fa-check text-success"></i>
      <i v-else class="fas fa-times text-danger"></i>
    </template>
    <template v-slot:cell(abgesagt)="row">
      <i v-if="row.item.abgesagt" class="fas fa-check text-success"></i>
      <i v-else class="fas fa-times text-danger"></i>
    </template>
    <template v-slot:cell(transferkostenHinWithCurrency)="row">
      <span
        >{{ row.item.metadata?.transferkostenHin }}
        <span v-if="row.item.metadata?.transferkostenWaehrung">{{
          getSymbol(row.item.metadata?.transferkostenWaehrung)
        }}</span>
      </span>
    </template>
    <template v-slot:cell(transferkostenRueckWithCurrency)="row">
      <span
        >{{ row.item.metadata?.transferkostenRueck }}
        <span v-if="row.item.metadata?.transferkostenWaehrung">{{
          getSymbol(row.item.metadata?.transferkostenWaehrung)
        }}</span>
      </span>
    </template>
    <template v-slot:cell(garantiert)="row">
      <i v-if="row.item.garantiert" class="fas fa-check text-success"></i>
      <i v-else class="fas fa-times text-danger"></i>
    </template>
    <template v-slot:cell(stopSale)="row">
      <i v-if="row.item.stopSale" class="fas fa-check text-success"></i>
      <i v-else class="fas fa-times text-danger"></i>
    </template>
    <template v-slot:cell(inlandsflugEinkauf)="row">
      <i v-if="inlandsflugEinkaufState(row.item) === 'skr'" class="fas fa-plane text-danger"></i>
      <i v-if="inlandsflugEinkaufState(row.item) === 'mixed'" class="fas fa-plane" style="color: #000"></i>
    </template>
    <template v-slot:cell(flugeinkaufEinkaufsart)="row">
      <span>{{ getEinkaufsartLabel(row.item?.flugeinkauf?.einkaufsart) }}</span>
    </template>
    <template v-slot:cell(flugeinkaufStatusgruppe)="row">
      <span>{{ getStatusgruppeLabel(row.item?.flugeinkauf?.statusgruppe) }}</span>
    </template>
    <template v-slot:cell(flugeinkaufGruppeFlughafen)="row">
      <span>{{ row.item?.flugeinkauf?.gruppeFlughafenIataCode }}</span>
    </template>
  </b-table>
</template>
<script>
import { formatDateWithoutHours, einkaufsartOptions, statusgruppeOptions } from '@/core/common/helpers/utils';
import PaxWidget from '@/components/produkte/reisetermine/pax-widget.vue';
import currenciesJson from '@/core/common/helpers/currencies.json';

export default {
  props: { reiseterminData: { type: Array, required: true }, fields: { type: Array, required: true } },
  components: {
    PaxWidget,
  },
  data() {
    return {};
  },
  computed: {},
  methods: {
    formatDateWithoutHours,
    rowClicked(item) {
      window.open('/#/reisetermine/' + item.id, '_blank');
    },
    getSymbol(code) {
      return currenciesJson[code]?.symbol;
    },
    inlandsflugEinkaufState(reisetermin) {
      if (reisetermin?.metadata?.inlandsfluege) {
        const { inlandsfluege } = reisetermin.metadata;

        if (inlandsfluege.every(inlandsflug => inlandsflug.einkauf === 'Skr')) return 'skr';
        if (inlandsfluege.some(inlandsflug => inlandsflug.einkauf === 'Dmc')) return 'mixed';
      }
      return null;
    },
    getEinkaufsartLabel(value) {
      return einkaufsartOptions.find(item => item.value === value)?.text;
    },
    getStatusgruppeLabel(value) {
      return statusgruppeOptions.find(item => item.value === value)?.text;
    },
    amtKalkulation(reisetermin) {
      const gesamtpreis = (reisetermin.thInlandsflug ?? 0) + (reisetermin.thLangstrecke ?? 0);
      return gesamtpreis.toString().replace('.', ',') + ' €';
    },
  },
};
</script>
