<template>
  <div>
    <div v-if="isZvcAufgabe">
      <ReiseterminVerlinkungenZvc :reiseterminData="reiseterminData" />
    </div>
    <!-- Releaseprozess Reisetermin Verlinkung -->
    <div v-else-if="isReleaseprozessAufgabe">
      <ReiseterminVerlinkungenReleaseprozess :reiseterminData="reiseterminData" />
    </div>
    <div v-else-if="isFomAufgabe">
      <ReiseterminVerlinkungenFom :reiseterminData="reiseterminData" />
    </div>
    <div v-else-if="aufgabenKategorie === 'GruppeneinkaufPruefen'" class="d-flex flex-column">
      <h6 class="ml-auto">
        <a :href="'#/reisetermine/flugplanung?reisetermine=' + joinedReiseterminkuerzel" target="_blank">
          <b-icon icon="box-arrow-up-right"></b-icon> Reisetermine in der Flugplanung
        </a>
      </h6>
      <ReiseterminVerlinkungenGeneric
        :reiseterminData="reiseterminData"
        :fields="fieldsGruppeneinkaufPruefen"
      />
    </div>
    <div v-else-if="aufgabenKategorie === 'AnlageHotelstamm'">
      <ReiseterminVerlinkungenGeneric :reiseterminData="reiseterminData" :fields="fieldsAnlageHotelstamm" />
    </div>
    <div v-else-if="aufgabenKategorie === 'AnlageFlugstamm'">
      <ReiseterminVerlinkungenGeneric :reiseterminData="reiseterminData" :fields="fieldsAnlageFlugstamm" />
    </div>
    <div v-else-if="aufgabenKategorie === 'OptionsfristErreicht'">
      <ReiseterminVerlinkungenGeneric
        :reiseterminData="reiseterminData"
        :fields="fieldsOptionsfristErreicht"
      />
    </div>
    <!-- Nicht Releaseprozess Reiseterminverlinkung: (MtzErreicht, MtzUnterschritten) -->
    <div v-else>
      <ReiseterminVerlinkungenBasic :reiseterminData="reiseterminData" />
    </div>
  </div>
</template>
<script>
import ReiseterminVerlinkungenZvc from '@/components/aufgaben/verlinkungen/reisetermin-verlinkungen-zvc.vue';
import ReiseterminVerlinkungenBasic from '@/components/aufgaben/verlinkungen/reisetermin-verlinkungen-basic.vue';
import ReiseterminVerlinkungenReleaseprozess from '@/components/aufgaben/verlinkungen/reisetermin-verlinkungen-releaseprozess.vue';
import ReiseterminVerlinkungenFom from '@/components/aufgaben/verlinkungen/reisetermin-verlinkungen-fom.vue';
import ReiseterminVerlinkungenGeneric from '@/components/aufgaben/verlinkungen/reisetermin-verlinkungen-generic.vue';
import { mapGetters } from 'vuex';
export default {
  components: {
    ReiseterminVerlinkungenZvc,
    ReiseterminVerlinkungenBasic,
    ReiseterminVerlinkungenReleaseprozess,
    ReiseterminVerlinkungenFom,
    ReiseterminVerlinkungenGeneric,
  },
  props: {
    aufgabenKategorie: { type: String, required: true },
    reiseterminData: { type: Array, required: true },
  },
  data() {
    return {
      fieldsGruppeneinkaufPruefen: [
        { key: 'reiseterminkuerzel', label: 'Reiseterminkürzel' },
        {
          key: 'metadata.transferszenarioHin',
          label: 'Transferszenario Hin',
          formatter: value => (value ? value.substring(0, 2) : '-'),
        },
        {
          key: 'metadata.transferszenarioRueck',
          label: 'Transferszenario Rück',
          formatter: value => (value ? value.substring(0, 2) : '-'),
        },
        {
          key: 'metadata.vkTransferkostenHin',
          label: 'VK Transferkosten Hin',
          formatter: value => (value ? String(value).replace('.', ',') + ' €' : '-'),
        },
        {
          key: 'metadata.vkTransferkostenRueck',
          label: 'VK Transferkosten Hin',
          formatter: value => (value ? String(value).replace('.', ',') + ' €' : '-'),
        },
      ],
      fieldsAnlageHotelstamm: [
        { key: 'reiseterminkuerzel', label: 'Reiseterminkürzel' },
        {
          key: 'metadata.hotelnaechte',
          label: 'Hotelnächte',
          formatter: value => value ?? '-',
        },
        {
          key: 'metadata.minPax',
          label: 'Min Pax',
          formatter: value => value ?? '-',
        },
        {
          key: 'metadata.maxPax',
          label: 'Max Pax',
          formatter: value => value ?? '-',
        },
        {
          key: 'metadata.releasefrist',
          label: 'Releasefrist',
          formatter: value => value ?? '-',
        },
        {
          key: 'metadata.hotelstartVorLandung',
          label: 'Hotelstart v. Landung',
        },
      ],
      fieldsAnlageFlugstamm: [
        { key: 'reiseterminkuerzel', label: 'Reiseterminkürzel' },
        {
          key: 'metadata.transferszenarioHin',
          label: 'Transferszenario Hin',
          formatter: value => (value ? value.substring(0, 2) : '-'),
        },
        {
          key: 'metadata.transferszenarioRueck',
          label: 'Transferszenario Rück',
          formatter: value => (value ? value.substring(0, 2) : '-'),
        },
        { key: 'transferkostenHinWithCurrency', label: 'Transferkosten Hin' },
        { key: 'transferkostenRueckWithCurrency', label: 'Transferkosten Rück' },
        {
          key: 'metadata.skrFlugangebot',
          label: 'SKR Flugangebot',
          formatter: value => (value ? value + ' €' : '-'),
        },
        { key: 'ziellandAbUndZielflughafen', label: 'Zielland Zielflughafen/Abflughafen' },
        { key: 'metadata.overnightOutbound', label: 'Overnight Outbound' },
        { key: 'metadata.overnightInbound', label: 'Overnight Inbound' },
        { key: 'metadata.hotelstartVorLandung', label: 'Hotelstart v. Landung' },
        {
          key: 'metadata.fruehesterAbflug',
          label: 'RES Hin Früh. Abflug',
          formatter: value => (value ? value.substring(0, 5) + ' Uhr' : '-'),
        },
        {
          key: 'metadata.spaetesterAbflug',
          label: 'RES Hin Spät. Abflug',
          formatter: value => (value ? value.substring(0, 5) + ' Uhr' : '-'),
        },
        {
          key: 'metadata.fruehesteAnkunft',
          label: 'RES Hin Früh. Ankunft',
          formatter: value => (value ? value.substring(0, 5) + ' Uhr' : '-'),
        },
        {
          key: 'metadata.spaetesteAnkunft',
          label: 'RES Hin Spät. Ankunft',
          formatter: value => (value ? value.substring(0, 5) + ' Uhr' : '-'),
        },
        {
          key: 'inlandsflugEinkauf',
          label: 'Inlandsflüge: Einkauf (SKR/DMC)',
        },
      ],
      fieldsOptionsfristErreicht: [
        { key: 'reiseterminkuerzel', label: 'Reiseterminkürzel' },
        { key: 'state', label: 'Status' },
        { key: 'flugeinkaufStatusgruppe', label: 'Statusgruppe' },
        { key: 'flugeinkaufEinkaufsart', label: 'Einkaufsart' },
        { key: 'pax', label: 'PAX' },
        { key: 'flugeinkauf.gruppePnr', label: 'Gruppe PNR' },
        { key: 'flugeinkauf.flugnummerGruppeHin', label: 'Flugnr. Gruppe Hin' },
        { key: 'flugeinkauf.flugnummerGruppeRueck', label: 'Flugnr. Gruppe Rück' },
        { key: 'flugeinkauf.eingekauftePax', label: 'Eingekaufte Pax' },
        { key: 'flugeinkaufGruppeFlughafen', label: 'Gruppe FH' },
        { key: 'flugeinkauf.ibClass', label: 'IB Class' },
        { key: 'flugeinkauf.obClass', label: 'OB Class' },
        {
          key: 'flugeinkauf.hauptstreckePreisNetto',
          label: 'Preis Hauptstrecke Netto',
          formatter: value => (value ? value + '€' : '-'),
        },
        { key: 'flugeinkauf.inlandsflugPreis', label: 'Preis Inlandsflug' },
        { key: 'flugeinkauf.taxesFlug', label: 'Taxes Flug' },
        { key: 'gesamtpreisFlugeinkauf', label: 'Gesamtpreis Gruppe', formatter: value => value + '€' },
        { key: 'thLangstrecke', label: 'TH Langstrecke', formatter: value => (value ? value + '€' : '-') },
        { key: 'thGesamt', label: 'TH Gesamt', formatter: value => (value ? value + '€' : '-') },
        {
          key: 'flugeinkauf.optionsfrist',
          label: 'Optionsfrist',
          formatter: value => (value ? value + ' Tage' : '-'),
        },
      ],
    };
  },
  computed: {
    ...mapGetters(['getKategorienReleaseprozess']),
    joinedReiseterminkuerzel() {
      if (!this.reiseterminData) return '';
      return this.reiseterminData.map(rt => rt.reiseterminkuerzel).join(',');
    },
    isReleaseprozessAufgabe() {
      return this.getKategorienReleaseprozess
        .map(kategorie => kategorie.aufgabenKategorie)
        .includes(this.aufgabenKategorie);
    },
    isFomAufgabe() {
      return this.aufgabenKategorie === 'FlugticketsAusstellen';
    },
    isZvcAufgabe() {
      return (
        this.aufgabeData?.kategorie === 'ZimmerkontingentePruefenMaxPax' ||
        this.aufgabeData?.kategorie === 'ZimmerkontingentePruefenErwartetePax'
      );
    },
  },
  methods: {},
};
</script>
