<template>
  <div>
    <b-table
      style="border: 1px solid rgb(0, 0, 0, 0.1); border-radius: 5px; margin-top: 12px"
      :items="reisenData"
      :fields="reisenFields"
      responsive
      hover
      head-variant="light"
      @row-clicked="rowClicked"
    >
      <template v-slot:cell(prioritaet)="row">
        <b-badge>{{ getValueByPriority(row.item.prioritaet) }}</b-badge>
      </template>
    </b-table>
  </div>
</template>
<script>
import configPriority from '@/core/produkte/reisen/priority.config.json';

export default {
  props: { reisenData: { type: Array, required: true } },
  data() {
    return {
      reisenFields: [
        {
          tdClass: 'align-middle',
          key: 'reisekuerzel',
          label: 'Reisekürzel',
        },
        {
          tdClass: 'align-middle',
          key: 'titel',
          label: 'Reisetitel',
        },
        {
          tdClass: 'align-middle',
          key: 'country',
          label: 'Land',
        },
        {
          tdClass: 'align-middle',
          key: 'region',
          label: 'Region',
        },
        {
          tdClass: 'align-middle',
          key: 'prioritaet',
          label: 'Prio.',
        },
      ],
    };
  },
  methods: {
    getVariantByPriority(priority) {
      return configPriority[priority].color;
    },
    getValueByPriority(priority) {
      return configPriority[priority].value;
    },
    rowClicked(item) {
      window.open('/#/reisen/' + item.reisekuerzel + '/edit', '_blank');
    },
  },
};
</script>
